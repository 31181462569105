<template>
  <v-select
    v-bind="$attrs"
    v-on="$listeners"
    :value="value"
    filled
    rounded
    hide-details="auto"
    @change="onChangeSelect"
  ></v-select>
</template>

<script>
// import { ref, watch } from '@vue/composition-api'

export default {
  props: {
    value: {
      default: null
    },
  },
  setup(props, { emit }) {

    const onChangeSelect = (value) => {
       emit('change', value)
    };
    

    // watch(() => props.items, (items) => { 
    //   getItems.value = items; 
    // })

    return {
      onChangeSelect
    }
  }  
}
</script>

<style>

</style>