<template>
  <div class="wrapper__upload-documents-page">
    <v-row class="ma-0">
      <v-col lg="8" class="pa-lg-10">
        <section class="p-lg-10">
          <h1 class="my-10">Bienvenidos a Pinos de Anchorena Vicente López</h1>
          
          <template v-if="!userDocsComplete">
            <p class="mb-6">
              <icon-alert-message />
              Por favor les solicitamos completar el formulario con la información solicitada. El mismo se divide en <strong>3 (tres) secciones:</strong> Reglamento Institucional, Datos Personales y Documentación Requerida. Cuando se completan todos los campos de una sección, esta cambiara el color <strong>rojo por verde</strong>. Para completar el formulario las <strong>3 (tres) secciones</strong> deberán estar en color <strong>verde</strong>.
            </p>
            <slot></slot>
          </template>

          <qr-code-user class="mt-8" />
        </section>
      </v-col>

      <v-col
        lg="4"
        class="d-none d-lg-block position-relative overflow-hidden pa-0 col-image-right"
      >
        <div class="image">
          <div class="d-flex flex-column">
            <div>
              <img
                width="100%"
                class=""
                :src="require(`@/assets/images/app/upload-documents.png`)"
              />
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref, onMounted, computed } from "@vue/composition-api";
import QrCodeUser from "./QrCodeUser.vue";
import IconAlertMessage from '@/components/icons/IconAlertMessage.vue';
import api from "@/services";

export default {
  components: { QrCodeUser, IconAlertMessage },
  setup(props) {
    const me = ref(null);
    const userDocsComplete = computed(() => !!me.value?.is_docs_complete);

    const getMe = async () => {
      try {
        const { data } = await api.getMe();
        me.value = data.data;

      } catch (error) {
      } finally {
      }
    };

    onMounted(() => {
      getMe();
    })

    return {
      userDocsComplete
    }
  }
};
</script>

<style></style>
