<template>
  <div class="">
    <p>
      <icon-alert-message />
      Antes de completar el formulario, por favor leer el REGLAMENTO INSTITUCIONAL. 
      <br />
      Una vez leído por favor marcar con un check si estás de acuerdo con los términos y condiciones expresados en el mismo.
    </p>

    <p>
      <a href="/docs/reglamento-pinosvl.pdf" target="_blank">
        > LEER REGLAMENTO INSTITUCIONAL
      </a>
    </p>
  </div>
</template>

<script>
import IconAlertMessage from '@/components/icons/IconAlertMessage.vue';

export default {
  components: { IconAlertMessage },
  name: "LegalInfo",
  data() {
    return {
    };
  },
};
</script>

<style></style>
