<template>
  <FormSelectField
    v-bind="$attrs"
    v-on="$listeners"
    label="Formaciones"
    :items="careers"
    :value="value"
    @change="onChangeSelect"
  />
</template>

<script>
import { computed } from "@vue/composition-api";

import FormSelectField from "@/components/forms/Select";
import careers from "@/utils/careers.util";

export default {
  components: {
    FormSelectField,
  },
  props: {
    value: {
      default: null,
    },
  },
  setup(props, { emit }) {
    const onChangeSelect = ({ value }) => {
      emit("change", value);
    };

    return {
      careers,
      onChangeSelect,
    };
  },
};
</script>

<style></style>
