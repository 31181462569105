<template>
  <v-card tile shaped>
    <v-card-text>
      <wrapper-form v-bind="$attrs" :model="userDocument">
        <p class="mb-6 mt-0">
          <icon-alert-message />
          Haz clic sobre el tipo de documento que quieras cargar y podrás seleccionar el archivo en tu ordenador. En caso que necesites volver a subir un documento ya cargado vuelve a hacer clic sobre el ícono y repite el proceso. Los formatos aceptados son PDF, JPG y PNG. Los archivos no deberán superar los 3 mb de tamaño.
        </p>

        <v-row>
          <v-col
            xs="12"
            sm="6"
            md="4"
            cols="12"
            v-for="({ ref, label }, index) in uploadToFiles"
            :key="index"
          >
            <file-input-custom
              :label="label"
              :path-file-upload="onValidUploadFile(ref)"
              @onSelectFile="(file) => onSelectFile(file, ref)"
            />
          </v-col>
        </v-row>
      </wrapper-form>

      <v-overlay :value="loading" absolute>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  ref,
  reactive,
  onMounted,
  computed,
  watch,
  getCurrentInstance,
} from "@vue/composition-api";

import api from "@/services";
import IconAlertMessage from '@/components/icons/IconAlertMessage.vue';
import { required, integerValidator } from "@core/utils/validation";
import WrapperForm from "@/components/general/WrapperForm";
import FileInputCustom from "@/components/forms/FileInputCustom";
import store from "@/store";

export default {
  name: "FormUploadDocument",
  components: {
    WrapperForm,
    FileInputCustom,
    IconAlertMessage,
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy;
    const loading = ref(false);
    const userDocument = ref(null);
    const userType = computed(() => store.getters["auth/userType"]);

    const uploadToFiles = computed(() => {
      let defaultDocuments = [
        {
          ref: "birth_certificate",
          label: "Partida de nacimiento",
        },
        {
          ref: "dni_front",
          label: "DNI frontal",
        },
        {
          ref: "dni_reverse",
          label: "DNI dorso",
        },
        {
          ref: "physical_fitness",
          label: "Apto psicofísico",
        },
        {
          ref: "analytical_certificate",
          label: "Certificado secundario",
        },
        {
          ref: "registration_sheet",
          label: "Certificado de titulo secundario en trámite",
        },
        {
          ref: "photo_4x4",
          label: "Foto 4x4",
        },
        {
          ref: "tetanus_vaccine",
          label: "Vacuna Antitetánica",
        },
        {
          ref: "hepatitis_vaccine",
          label: "Vacuna Antihepatitis B",
        },
        {
          ref: "covid_vaccine",
          label: "Vacuna COVID",
        },
      ];
      switch (userType.value) {
        case "teacher":
          return (defaultDocuments = [
            ...defaultDocuments,
            {
              ref: "curriculum_vitae",
              label: "Curriculum vitae",
            },
          ]);
        case "pass_student":
          return (defaultDocuments = [...defaultDocuments]);

        default:
          return defaultDocuments;
      }
    });



    const onInputForm = (v) => {
      emit("input", v);
    };

    const onValidUploadFile = (ref) => {
      return userDocument.value && userDocument.value[ref];
    };

    const onToggleLoading = (value = null) => {
      loading.value = value || !loading.value;
    };

    const onSelectFile = async ({ file }, ref) => {
      const formData = new FormData();

      formData.append("ref", ref);
      formData.append("file", file);

      try {
        onToggleLoading();
        const { data: response } = await api.updateMeDocument(formData);
        const { data } = response;

        userDocument.value = { ...data };
      } catch (error) {
        const {data} = error.response;
        const {message = null} = data;

        vm.$alert(message, null, "error");
      } finally {
        onToggleLoading();
      }
    };

    const getMeDocument = async () => {
      try {
        onToggleLoading();

        const { data: response } = await api.getMeDocument();
        const { success, data } = response;
        if (success) userDocument.value = { ...data };
      } catch (error) {
      } finally {
        onToggleLoading();
      }
    };

    watch(
      () => userDocument.value,
      () => {
        let result = true;
        uploadToFiles.value.forEach((f) => {
          if (!userDocument.value[f.ref]) result = false;
        });
        emit("input", result);
      },
      { deep: true }
    );

    onMounted(() => {
      getMeDocument();
    });

    return {
      uploadToFiles,
      userDocument,
      loading,

      validators: {
        required,
        integerValidator,
      },

      getMeDocument,
      onSelectFile,
      onValidUploadFile,
    };
  },
};
</script>

<style></style>
