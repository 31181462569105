<template>
  <div
    class="wrapper__file-input-custom"
    :class="{ 'document-is-valid': isValidFile || pathFileUpload }"
    @click.stop="onClickInputFile"
  >
    <div
      class="text-center d-flex flex-column align-center justify-center flex-grow-1"
    >
      <icon-document />
      <h5>{{ label }}</h5>

      <!-- <v-chip
        class="mt-2"
        close
        @click:close="onDeleteFile"
        v-if="selectedFile"
      >
        <p class="d-inline-block text-truncate mb-0">{{ selectedFile.name }}</p>
      </v-chip> -->
      <input
        style="display: none"
        type="file"
        ref="inputFile"
        @change="onSelectFile"
      />

      <a 
        v-if="pathFileUpload"
        @click.stop="() => {}"
        :href="pathFileUpload"
        target="_blank" class="mt-2"><small>Ver archivo</small></a>
    </div>
  </div>
</template>

<script>
import { ref, getCurrentInstance, computed } from "@vue/composition-api";
import IconDocument from "@/components/icons/IconDocument";

export default {
  props: {
    label: {
      default: "Selecciona un archivo",
      type: String,
    },
    pathFileUpload: {
      default: null,
      type: String,
    },
  },
  components: {
    IconDocument,
  },
  setup(props, { emit }) {
    const vm = getCurrentInstance().proxy;

    const selectedFile = ref(null);
    const isValidFile = computed(
      () => !!selectedFile.value
    );

    const onClickInputFile = () => {
      vm.$refs.inputFile.click();
    };

    const onSelectFile = (event) => {
      const file = event.target.files[0];

      if(file.size > 3000000) {
        vm.$alert("Archivo supera 3MB de tamaño", null, "error");
        return;
      }
      selectedFile.value = file;

      emit("onSelectFile", { file });
    };

    const onDeleteFile = () => {
      selectedFile.value = null;
    };

    return {
      selectedFile,
      isValidFile,

      onClickInputFile,
      onSelectFile,
      onDeleteFile,
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper__file-input-custom {
  width: 200px;
  border: dashed 1px #e2e2e2;
  min-height: 250px;
  cursor: pointer;
  border-radius: 5px;
  padding: 1rem 0.5rem;
  margin: auto;

  .v-input {
    display: none;
  }
}
</style>
