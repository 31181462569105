<template>
  <upload-documents />
</template>

<script>
import UploadDocuments from '@/components/general/UploadDocuments';

export default {
  components: {
    UploadDocuments,
  },
  setup(props) {},
};
</script>

<style lang="scss"></style>
