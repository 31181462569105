<template>
  <v-text-field
    v-bind="$attrs"
    v-on="$listeners"
    :value="value"
    filled
    rounded
    hide-details="auto"
    @input="onChangeInput"
  ></v-text-field>
</template>

<script>
// import { ref, watch } from "@vue/composition-api";

export default {
  props: {
    value: {
      default: "",
    },
  },
  setup(props, { emit }) {

    const onChangeInput = (value) => emit("input", value);
    return {
      onChangeInput,
    };
  },
};
</script>

<style></style>
