<template>
   <v-icon>{{ mdiCommentAlertOutline }}</v-icon>
</template>

<script>
import { mdiCommentAlertOutline } from "@mdi/js";

export default {
  data() {
    return {
      mdiCommentAlertOutline
    }
  },
}
</script>

<style>

</style>