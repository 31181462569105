<template>
  <svg
    id="svg4063"
    version="1.1"
    viewBox="0 0 32 32.000001"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns:svg="http://www.w3.org/2000/svg"
  >
    <defs id="defs4065" />
    <g id="layer1" transform="translate(0,-1020.3621)">
      <g id="g7300">
        <path
          d="m 25,1028.3621 0,18.5 c 0,0.831 -0.669,1.5 -1.50001,1.5 l -15.99997,0 c -0.83101,0 -1.50002,-0.669 -1.50002,-1.5 l 0,-22 c 0,-0.831 0.66901,-1.5 1.50002,-1.5 l 12.49998,0"
          id="path5384"
          style="
            opacity: 1;
            fill: #e1e1e1;
            fill-opacity: 1;
            stroke: none;
            stroke-width: 1;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 4.0999999;
            stroke-dasharray: none;
          "
        />
        <path
          d="m 20.00003,1023.3621 0,3.5 c 0,0.831 0.66899,1.5 1.5,1.5 l 3.5,0 z"
          id="path5386"
          style="
            opacity: 1;
            fill: #d2d2d2;
            fill-opacity: 1;
            stroke: none;
            stroke-width: 1;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 4.0999999;
            stroke-dasharray: none;
          "
        />
        <path
          d="m 10.007812,1033.8613 a 0.50005,0.50005 0 1 0 0,1 0.50005,0.50005 0 1 0 0,-1 z m 2,0 a 0.50005,0.50005 0 1 0 0,1 l 4.625,0 4.367188,0 a 0.50005,0.50005 0 1 0 0,-1 l -4.367188,0 -4.625,0 z"
          id="path5388"
          style="
            color: #000000;
            font-style: normal;
            font-variant: normal;
            font-weight: normal;
            font-stretch: normal;
            font-size: medium;
            line-height: normal;
            font-family: sans-serif;
            text-indent: 0;
            text-align: start;
            text-decoration: none;
            text-decoration-line: none;
            text-decoration-style: solid;
            text-decoration-color: #000000;
            letter-spacing: normal;
            word-spacing: normal;
            text-transform: none;
            direction: ltr;
            block-progression: tb;
            writing-mode: lr-tb;
            baseline-shift: baseline;
            text-anchor: start;
            white-space: normal;
            clip-rule: nonzero;
            display: inline;
            overflow: visible;
            visibility: visible;
            opacity: 1;
            isolation: auto;
            mix-blend-mode: normal;
            color-interpolation: sRGB;
            color-interpolation-filters: linearRGB;
            solid-color: #000000;
            solid-opacity: 1;
            fill: #f9f9f9;
            fill-opacity: 1;
            fill-rule: evenodd;
            stroke: none;
            stroke-width: 1px;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-dashoffset: 0;
            stroke-opacity: 1;
            color-rendering: auto;
            image-rendering: auto;
            shape-rendering: auto;
            text-rendering: auto;
            enable-background: accumulate;
          "
        />
        <path
          d="m 10.007812,1035.8613 a 0.50005,0.50005 0 1 0 0,1 0.50005,0.50005 0 1 0 0,-1 z m 2,0 a 0.50005,0.50005 0 1 0 0,1 l 4.625,0 4.367188,0 a 0.50005,0.50005 0 1 0 0,-1 l -4.367188,0 -4.625,0 z"
          id="path5390"
          style="
            color: #000000;
            font-style: normal;
            font-variant: normal;
            font-weight: normal;
            font-stretch: normal;
            font-size: medium;
            line-height: normal;
            font-family: sans-serif;
            text-indent: 0;
            text-align: start;
            text-decoration: none;
            text-decoration-line: none;
            text-decoration-style: solid;
            text-decoration-color: #000000;
            letter-spacing: normal;
            word-spacing: normal;
            text-transform: none;
            direction: ltr;
            block-progression: tb;
            writing-mode: lr-tb;
            baseline-shift: baseline;
            text-anchor: start;
            white-space: normal;
            clip-rule: nonzero;
            display: inline;
            overflow: visible;
            visibility: visible;
            opacity: 1;
            isolation: auto;
            mix-blend-mode: normal;
            color-interpolation: sRGB;
            color-interpolation-filters: linearRGB;
            solid-color: #000000;
            solid-opacity: 1;
            fill: #f9f9f9;
            fill-opacity: 1;
            fill-rule: evenodd;
            stroke: none;
            stroke-width: 1px;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-dashoffset: 0;
            stroke-opacity: 1;
            color-rendering: auto;
            image-rendering: auto;
            shape-rendering: auto;
            text-rendering: auto;
            enable-background: accumulate;
          "
        />
        <path
          d="m 10.007812,1037.8613 a 0.50005,0.50005 0 1 0 0,1 0.50005,0.50005 0 1 0 0,-1 z m 2,0 a 0.50005,0.50005 0 1 0 0,1 l 4.625,0 4.367188,0 a 0.50005,0.50005 0 1 0 0,-1 l -4.367188,0 -4.625,0 z"
          id="path5392"
          style="
            color: #000000;
            font-style: normal;
            font-variant: normal;
            font-weight: normal;
            font-stretch: normal;
            font-size: medium;
            line-height: normal;
            font-family: sans-serif;
            text-indent: 0;
            text-align: start;
            text-decoration: none;
            text-decoration-line: none;
            text-decoration-style: solid;
            text-decoration-color: #000000;
            letter-spacing: normal;
            word-spacing: normal;
            text-transform: none;
            direction: ltr;
            block-progression: tb;
            writing-mode: lr-tb;
            baseline-shift: baseline;
            text-anchor: start;
            white-space: normal;
            clip-rule: nonzero;
            display: inline;
            overflow: visible;
            visibility: visible;
            opacity: 1;
            isolation: auto;
            mix-blend-mode: normal;
            color-interpolation: sRGB;
            color-interpolation-filters: linearRGB;
            solid-color: #000000;
            solid-opacity: 1;
            fill: #f9f9f9;
            fill-opacity: 1;
            fill-rule: evenodd;
            stroke: none;
            stroke-width: 1px;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-dashoffset: 0;
            stroke-opacity: 1;
            color-rendering: auto;
            image-rendering: auto;
            shape-rendering: auto;
            text-rendering: auto;
            enable-background: accumulate;
          "
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style></style>
