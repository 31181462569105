<template>
  <FormSelectField
    v-bind="$attrs"
    v-on="$listeners"
    label="País"
    :items="countriesMap"
    item-text="text"
    item-value="value"
    :value="value"
    @change="onChangeSelect"
  />
</template>

<script>
import { computed } from "@vue/composition-api";

import FormSelectField from "@/components/forms/Select";
import countries from "@/utils/countries.util";

export default {
  components: {
    FormSelectField,
  },
  props: {
    value: {
      default: null,
    },
  },
  setup(props, { emit }) {
    const countriesMap = computed(() => {
      return Object.keys(countries).map((key) => ({
        value: key,
        text: countries[key],
      }));
    });

    const onChangeSelect = ({ value }) => {
      emit("change", value);
    };

    return {
      countriesMap,
      onChangeSelect,
    };
  },
};
</script>

<style></style>
