<template>
  <wrapper-upload-documents>
    <section class="form" 
      v-if="userDetail"
    >
      <v-stepper v-model="activeStep" vertical class="custom-header">
         <!-- Step 1 -->
         <v-stepper-step
          :complete="activeStep !== 1 && isFormInfoLegalValid"
          :rules="[() => isFormInfoLegalValid]"
          :step="1"
          @click="nextStep(1)"
        >
          <div class="d-flex align-center">
            <span class="text--primary text-4xl font-weight-bold me-3">01</span>
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold"
                >Reglamento Institucional</span
              >
              <span class="text--secondary text-xs"
                >Leer el Reglamento y aceptar los términos y condiciones</span
              >
            </div>
          </div>
        </v-stepper-step>

        <v-stepper-content step="1">
          <legal-info />

          <v-checkbox
            :disabled="isFormInfoLegalValid"
            v-model="userDetail.accept_terms"
            @change="onChangeCheckboxTerms"
            :label="`Acepto los términos y condiciones del REGLAMENTO INSTITUCIONAL`"
          ></v-checkbox>

          <v-row>
            <v-col xs="6"></v-col>
            <v-col xs="6">
              <v-btn
                color="primary"
                class="my-4 float-right"
                rounded
                @click="nextStep(2)"
              >
                Siguiente
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <!-- Step 2 -->
        <v-stepper-step
          :complete="activeStep !== 2 && isFormPersonalInfoValid"
          :rules="[() => isFormPersonalInfoValid]"
          :step="2"
          @click="nextStep(2)"
        >
          <div class="d-flex align-center">
            <span class="text--primary text-4xl font-weight-bold me-3">02</span>
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold"
                >Datos Personales</span
              >
              <span class="text--secondary text-xs"
                >Completar todos los campos con la información requerida</span
              >
            </div>
          </div>
        </v-stepper-step>

        <v-stepper-content step="2">
          <form-personal-info v-model="isFormPersonalInfoValid" />

          <v-row>
            <v-col xs="6"></v-col>
            <v-col xs="6">
              <v-btn
                color="primary"
                class="my-4 float-right"
                rounded
                @click="nextStep(3)"
              >
                Siguiente
              </v-btn>
            </v-col>
          </v-row>
        </v-stepper-content>

        <!-- Step 3 -->
        <v-stepper-step
          :complete="activeStep !== 3 && isFormDocumentsValid"
          :rules="[() => isFormDocumentsValid]"
          :step="3"
          @click="nextStep(3)"
        >
          <div class="d-flex align-center">
            <span class="text--primary text-4xl font-weight-bold me-3">03</span>
            <div class="d-flex flex-column">
              <span class="text--primary text-sm font-weight-semibold"
                >Documentación Requerida</span
              >
              <span class="text--secondary text-xs"
                >Completar todos los campos con los documentos solicitados</span
              >
            </div>
          </div>
        </v-stepper-step>

        <v-stepper-content step="3">
          <form-upload-document v-model="isFormDocumentsValid" />

          <!-- <v-row>
            <v-col xs="6">
              <v-btn color="secondary" class="my-4" rounded @click="backStep">
                Anterior
              </v-btn>
            </v-col>
          </v-row> -->
        </v-stepper-content>
      </v-stepper>
      
      <v-overlay :value="loading">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
    </section>
  </wrapper-upload-documents>
</template>

<script>
import { ref, onMounted, computed } from "@vue/composition-api";
import FileInputCustom from "@/components/forms/FileInputCustom";
import WrapperUploadDocuments from "@/components/general/WrapperUploadDocuments";
import FormPersonalInfo from "@/components/forms/FormPersonalInfo";
import FormUploadDocument from "@/components/forms/FormUploadDocument";
import LegalInfo from './LegalInfo.vue';
import store from '@/store'
import api from "@/services";

export default {
  components: {
    FileInputCustom,
    WrapperUploadDocuments,
    FormPersonalInfo,
    FormUploadDocument,
    LegalInfo,
  },

  setup(props) {
    const activeStep = ref(1);
    const loading = ref(false);
    const isFormPersonalInfoValid = ref(false);
    const isFormDocumentsValid = ref(false);

    const userDetail = computed(() => store.getters["user/userDetail"]);
    const isFormInfoLegalValid = computed(() => !!userDetail.value?.accept_terms);

    const onToggleLoading = (value = null) => {
      loading.value = value || !loading.value;
    };

    const onChangeCheckboxTerms = async () => {
      try {
        await api.updateMeDetail(userDetail.value);
      } catch (error) {
      } finally {
      }
    };

    const getMeDetail = async () => {
      try {
        onToggleLoading();

        await store.dispatch('user/onGetUserDetail')
      } catch (error) {
      } finally {
        onToggleLoading();
      }
    };

    const nextStep = (step = 1) => {
      if(!isFormInfoLegalValid.value) return;
      activeStep.value = step;
    };

    const backStep = () => {
      activeStep.value -= 1;
    };

    onMounted(() => {
      getMeDetail();
    })

    return {
      userDetail,
      loading,
      activeStep,
      isFormPersonalInfoValid,
      isFormDocumentsValid,
      isFormInfoLegalValid,
      
      onChangeCheckboxTerms,
      nextStep,
      backStep,
    };
  },
};
</script>

<style lang="scss">
.wrapper__upload-documents-page {
  .v-stepper {
    .v-stepper__content {
      margin-top: 2rem !important;
    }

    .v-stepper__label {
      cursor: pointer;
    }
  }

  .col-image-right {
    // position: absolute;
    // right: 0;
    // top: 0;
    .image {
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
